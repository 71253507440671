import { $themeBreakpoints } from '@themeConfig'

export default {
  namespaced: true,
  state: {
    windowWidth: 0,
    requestsPending: 0,
    shallShowOverlay: false,
    overlayMessage: null,
    localUserData: {},
    msgRefreshIntervalId: null,
    mailboxCounts: [],
    currentSoftware: '',
    currentWorkspace: {},
    trashCount: 0,
    leavesCount: 0,
    timesheetsCount: 0
  },
  getters: {
    currentBreakPoint: (state) => {
      const { windowWidth } = state
      if (windowWidth >= $themeBreakpoints.xl) return 'xl'
      if (windowWidth >= $themeBreakpoints.lg) return 'lg'
      if (windowWidth >= $themeBreakpoints.md) return 'md'
      if (windowWidth >= $themeBreakpoints.sm) return 'sm'
      return 'xs'
    },
    currentWindowWidth: (state) => {
      return state.windowWidth
    },
    currentTrashCount: (state) => {
      return state.trashCount
    },
    currentLeaveCount: (state) => {
      return state.leavesCount
    },
    currentTimesheetsCount: (state) => {
      return state.timesheetsCount
    },
    currentMailboxCounts: (state) => (mailboxId) => {
        return state.mailboxCounts.find(mb => mb.mailboxId === mailboxId)
    },
    currentSoftware: (state) => {
        return state.currentSoftware
    },
    currentWorkspace: (state) => {
        return state.currentWorkspace
    },
  },
  mutations: {
    SET_TRASH_COUNT(state, count) {
      state.trashCount = count
    },
    SET_LEAVE_COUNT(state, count) {
      state.leavesCount = count
    },
    SET_TIMESHEETS_COUNT(state, count) {
      state.timesheetsCount = count
    },
    SET_OVERLAY_MESSAGE(state, message) {
        state.overlayMessage = message;
    },
    SET_CURRENT_SOFTWARE(state, software) {
      state.currentSoftware = software;
    },
    SET_CURRENT_WORKSPACE(state, workspace) {
      state.currentWorkspace = workspace;
    },
    SET_MSG_REFRESH_INTERVAL_ID(state, intervalId) {
        state.msgRefreshIntervalId = intervalId
    },
    SET_MSG_MAILBOX_COUNT(state, mailboxCounts) {
        state.mailboxCounts = mailboxCounts
    },
    UPDATE_LOCAL_USER(state, obj) {
        state.localUserData = obj
    },
    UPDATE_WINDOW_WIDTH(state, val) {
      state.windowWidth = val
    },
    TOGGLE_OVERLAY(state, val) {
      state.shallShowOverlay = val !== undefined ? val : !state.shallShowOverlay
    },
    REQUEST_PENDING(state) {
      if (state.requestsPending === 0) {
        this.commit('app/TOGGLE_OVERLAY', true)
      }
      state.requestsPending++
    },
    REQUEST_DONE(state) {
      if (state.requestsPending >= 1) {
        state.requestsPending--
      }

      if (state.requestsPending <= 0) {
        this.commit('app/TOGGLE_OVERLAY', false)
        this.commit('app/SET_OVERLAY_MESSAGE', null)
      }
    }
  },
  actions: {}
}
