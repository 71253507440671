/* eslint-disable */
import {computed} from "@vue/composition-api";
import VueCompositionApi from '@vue/composition-api'
import { i18n } from '@/libs/i18n';
import { useUtils as useI18nUtils } from '@core/libs/i18n';
const { i18n: i18nFunc } = useI18nUtils();
import Vue from "vue";
Vue.use(VueCompositionApi)

function i18nT(str) {
  return i18nFunc(str);
}


export default [
  {
    path: '/em/surveys',
    name: 'em-surveys',
    component: () => import('@/views/engagement/Surveys/SurveyList.vue'),
    meta: {
      pageTitle: computed(() => i18nT(`Surveys`)),
      breadcrumb: [
        {
          text: computed(() => i18nT(`Surveys`)),
          active: true
        }
      ]
    }
  },
  {
    path: '/em/surveys/view/:id',
    name: 'em-view-survey',
    component: () => import('@/views/engagement/Surveys/ViewSurvey.vue'),
    meta: {
      navActiveLink: 'surveys',
      pageTitle: computed(() => i18nT(`View Survey`)),
      breadcrumb: [
        {
          text: computed(() => i18nT(`Home`)),
          to: '/',
        },
        {
          text: computed(() => i18nT(`Surveys`)),
          to: '/em/surveys',
        },
        {
          text: computed(() => i18nT(`Survey List`)),
          to: '/em/surveys',
        },
        {
          text: computed(() => i18nT(`Survey`)),
          active: true,
        },
      ],
    },
  },
  {
    path: '/em/surveys/result/:id',
    name: 'em-result-survey',
    component: () => import('@/views/engagement/Surveys/ResultSurvey.vue'),
    meta: {
      navActiveLink: 'surveys',
      pageTitle: computed(() => i18nT(`Survey Result`)),
      breadcrumb: [
        {
          text: computed(() => i18nT(`Home`)),
          to: '/',
        },
        {
          text: computed(() => i18nT(`Survey List`)),
          to: '/em/surveys',
        },
        {
          text: computed(() => i18nT(`Survey Result`)),
          active: true,
        },
      ],
    },
  },
  {
    path: '/em/suggestions',
    name: 'em-suggestions',
    component: () =>
      import('@/views/engagement/Suggestions/SuggestionList.vue'),
    meta: {
      pageTitle: computed(() => i18nT(`Suggestions`)),
      breadcrumb: [
        {
          text: computed(() => i18nT(`Suggestions`)),
          active: true
        }
      ]
    }
  },
  {
    path: '/em/leave_absence',
    name: 'em-leave-absence',
    component: () => import('@/views/employee/LeaveRequest/LeaveRequest.vue'),
    meta: {
      pageTitle: computed(() => i18nT(`Leave Absence`)),
      breadcrumb: [
        {
          text: computed(() => i18nT(`Leave Absence`)),
          active: true
        }
      ]
    }
  },
  {
    path: '/em/timesheets',
    name: 'em-timesheets',
    component: () => import('@/views/employee/Timesheet/Timesheet.vue'),
    meta: {
      pageTitle: computed(() => i18nT(`Timesheets`)),
      breadcrumb: [
        {
          text: computed(() => i18nT(`Timesheets`)),
          active: true
        }
      ]
    }
  },
  {
    path: '/em/dashboard',
    name: 'em-dashboard',
    component: () => import('@/views/dashboard/em_Dashboard.vue'),
    meta: {
      pageTitle: computed(() => i18nT(`Dashboard`)),
      breadcrumb: [
        {
          text: computed(() => i18nT(`Dashboard`)),
          active: true
        }
      ]
    }
  }
]

/* eslint-enable */
