//import {dictToSelectArray, dictToSelectArrayWithTitle} from "@core/utils/utils";
import axiosIns from "@/libs/axios";
function dictToSelectArrayWithTitle(dict) {
	const array = [];

	const keys = Object.keys(dict);
	keys.forEach((key) => {
		array.push({
			value: key,
			title: dict[key],
		});
	});

	return array;
};

function dictToSelectArray (dict) {
	const array = [];

	const keys = Object.keys(dict);
	keys.forEach((key) => {
		array.push({
			value: key,
			text: dict[key],
		});
	});

	return array;
};

export default {
	namespaced: true,
	state: {
		enumsPopulated: false,
		invoiceEnums: {
			vendorList: [],
			typeList: [],
			paymentTypeList: [],
			currencyList: [],
			paymentCategoryList: [],
			recordTypeList: [],
			employeesList: [],
			employeeGroupsList: [],
			repeatOptions: [],
			repeatWeeklyOptions: [],
			repeatMonthlyOptions: [],
			repeatDaysOfMonths: [],
		},
		paymentMethodEnums: {
			paymentMethodList: [],
			paymentProviderList: [],
		},
		invoiceDetails: {},
		quoteDetails: {},
		payOptions: [],
		paymentProviders: [],
	},
	getters: {
		getEnumsState(state){
			return state.enumsPopulated;
		},
		getInvoiceEnums(state){
			return state.invoiceEnums;
		},
		getInvoiceData(state){
			return state.invoiceDetails;
		},
		getPaymentProviders(state){
			return state.payOptions;
		},
		getQuoteData(state){
			return state.quoteDetails;
		},
	},
	mutations: {
		SET_INVOICE_DETAILS(state, invoiceDetails) {
			state.invoiceDetails = invoiceDetails;
		},
		SET_QUOTE_DETAILS(state, invoiceDetails) {
			state.quoteDetails = invoiceDetails;
		},
		SET_PAY_OPTIONS(state, payOptions) {
			state.payOptions = payOptions;
		},
		SET_PAYMENT_METHOD_ENUMS(state, enums) {

		}
	},
	actions: {

		fetchPaymentMethodEnums(ctx) {
			axiosIns.get(`paymentMethods`).then(({data}) => {
				ctx.commit('SET_PAYMENT_METHOD_ENUMS', data.data);
			});
		},
		fetchInvoiceDetails(ctx, invId) {
			axiosIns.get(`invoices?id=${invId}`).then(({data}) => {
				ctx.commit('SET_INVOICE_DETAILS', data.data);
			});
		},

		fetchQuoteDetails(ctx, invId) {
			axiosIns.get(`quotes?id=${invId}`).then(({data}) => {
				ctx.commit('SET_QUOTE_DETAILS', data.data);
			});
		},
		fetchPublicInvoiceDetails(ctx, params) {
			axiosIns.get(`invoices/publicInvoice?id=${params.id}&hash=${params.hash}`).then(({data}) => {
				if(data.data){
					ctx.commit('SET_INVOICE_DETAILS', data.data.invoice);
					ctx.commit('SET_PAY_OPTIONS', data.data.payment_providers);
				}
			}).catch((err) => {
				window.location.href = '/error-404';
			});
		},
		fetchPublicQuoteDetails(ctx, params) {
			axiosIns.get(`quotes/publicQuote?id=${params.id}&hash=${params.hash}`).then(({data}) => {
				if(data.data){
					ctx.commit('SET_QUOTE_DETAILS', data.data);
				}
			}).catch((err) => {
				window.location.href = '/error-404';
			});
		},
		populateEnums(ctx) {

			Promise.all([

					axiosIns.get(
						`vendors/vendorsList`
					).then(({data}) => {
						ctx.state.invoiceEnums.vendorList = dictToSelectArray(data.data)
						//res()
					}),

					axiosIns.get(
						`system/financeTypes`
					).then(({data}) => {
						ctx.state.invoiceEnums.typeList = dictToSelectArray(data.data)
						//res()
					}),


					axiosIns.get(
						`system/paymentTypes`
					).then(({data}) => {
						ctx.state.invoiceEnums.paymentTypeList = dictToSelectArray(data.data)
					}),


					axiosIns.get(
						`system/currencies`
					).then(({data}) => {
						ctx.state.invoiceEnums.currencyList = dictToSelectArray(data.data)
					}),

					axiosIns.get(
						`invoices/categories`
					).then(({data}) => {
						ctx.state.invoiceEnums.paymentCategoryList = dictToSelectArray(data.data)

					}),

					axiosIns.get(
						`system/recordTypes`
					).then(({data}) => {
						ctx.state.invoiceEnums.recordTypeList = dictToSelectArray(data.data)
					}),

					axiosIns.get(
						`leaves/employeesFilter`
					).then(({data}) => {
						ctx.state.invoiceEnums.employeesList = dictToSelectArray(data.data)
					}),

					axiosIns.get(`invoices/repeatOptions`).then(({data}) => {
						ctx.state.invoiceEnums.repeatOptions = dictToSelectArray(data.data)

					}),

					axiosIns.get(`invoices/repeatWeekly`).then(({data}) => {
						ctx.state.invoiceEnums.repeatWeeklyOptions = dictToSelectArrayWithTitle(data.data)
					}),


					axiosIns.get(`invoices/repeatMonthly`).then(({data}) => {
						ctx.state.invoiceEnums.repeatMonthlyOptions = dictToSelectArrayWithTitle(data.data)
					}),


					axiosIns.get(`invoices/daysOfMonth`).then(({data}) => {
						ctx.state.invoiceEnums.repeatDaysOfMonths = dictToSelectArrayWithTitle(data.data)
					}),


					axiosIns.get(
						`employees/employeeGroups?show_per_page=100`
					).then(({data}) => {
						ctx.state.invoiceEnums.employeeGroupsList = data.data.employee_groups.map(employeeGroup => ({
							value: employeeGroup.group.Id,
							text: employeeGroup.group.Label
						}))

					}),
			]).then(()=>{
				ctx.state.enumsPopulated = true;
				console.log("response values: ", ctx.state.invoiceEnums);
			});

		},
	}
}
