import useAuth from '@/auth/useAuth';

//export const canNavigate = to => to.matched.some(route => ability.can(route.meta.action || 'read', route.meta.resource))
export const canNavigate = to => {
	if (!to.meta.roles) {
		return true;
	}

	if (!useAuth.hasRole(to.meta.roles)) {
		return false;
	}

	if (!to.meta.module) {
		return true;
	}

	return to.meta.mode === 'write' ? useAuth.iCan(to.meta.module, 'write') : useAuth.iCan(to.meta.module);
}
export const _ = undefined
