import axios from "@/libs/axios";

export default {
	namespaced: true,
	state: {
		currentMessage: {User: {}},
		currentSubject: '',
		recipients: [],
		allMessages: [],
		topMessages: [],
		msgRefreshIntervalId: null,
		isReply: false,
		parentThreadId: null,
		recipientId: null,
	},
	getters: {
		currentMessage(state) {
			return {...state.currentMessage}
		},
		currentSubject: state => state.currentSubject,
		recipients: state => state.recipients,
		getAllMessages: (state) => {
			return state.messages;
		},
		getTopMessages: (state) => {
			return state.topMessages;
		},
		getParentThreadId: (state) => {
			return state.parentThreadId;
		},
		getIsReply: (state) => {
			return state.isReply;
		},
		getRecipientId: (state) => {
			return state.recipientId;
		}
	},
	mutations: {
		SET_MSG_REFRESH_INTERVAL_ID(state, intervalId) {
			state.msgRefreshIntervalId = intervalId
		},
		SET_IS_REPLY(state, isReply) {
			state.isReply = isReply;
		},
		SET_MESSAGES(state, messages) {
			state.allMessages = messages;
		},
		SET_CURRENT_MESSAGE(state, message) {
			state.currentMessage = message
		},
		SET_RECIPIENT_ID(state, recipientId) {
			state.recipientId = recipientId;
		}

	},
	actions: {
		fetchEmails(ctx, payload) {
			let messageBase = `messages?`;
			// if (payload && payload.folder) {
			// 	messageBase += `outbox=${payload.folder === 'sent' ? 1 : 0}`;
			// }

			if (payload.softwares) {
				messageBase += `softwares=` + payload.softwares.join(',');
			}

			if (payload.usingArchive) {
				messageBase += `&archive=1`;
			}

			return new Promise((resolve, reject) => {
				axios
					.get(messageBase)
					.then(({data}) => {
						ctx.state.allMessages = data.data;
						if (data.data.length > 0) {

							ctx.state.topMessages = [];
							data.data.forEach(emailThread => {

								let firstMesssage = emailThread.Replies[0];

								if (payload.folder === 'sent') {
									firstMesssage.User = emailThread.Replies[0].RecipientObj;
								} else {
									firstMesssage.User = emailThread.Replies[0].SenderObj;
								}

								firstMesssage.parentThread = emailThread;
								firstMesssage.Replies = emailThread.Replies;
								firstMesssage.ReplyAvailable = emailThread.ReplyAvailable;

								ctx.state.topMessages.push(firstMesssage)
								ctx.state.parentThreadId = emailThread.Id;

							});
							//console.log("Resorted messages: ", ctx.state.topMessages);
							resolve({
								data: {
									emails: data.data.map((email) => {
										if (payload.folder === 'sent') {
											email.User = email.RecipientObj;
										} else {
											email.User = email.SenderObj;
										}
										return email;
									}),
								},
							});

						} else {
							ctx.state.topMessages = [];
							resolve({
								data: {
									emails: []
								},
							});
							//console.log("Blank messages: ", ctx.state.topMessages);
						}

					});
			});
		},


	}
}
