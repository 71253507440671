import {computed} from "@vue/composition-api";
import VueCompositionApi from '@vue/composition-api'
import { i18n } from '@/libs/i18n';
import { useUtils as useI18nUtils } from '@core/libs/i18n';
const { i18n: i18nFunc } = useI18nUtils();
import Vue from "vue";
Vue.use(VueCompositionApi)

function i18nT(str) {
    return i18nFunc(str);
}

export default [{
        path: '/dashboard/analytics',
        name: 'dashboard-analytics',
        component: () =>
            import ('@/views/dashboard/analytics/Analytics.vue'),
        meta: {
            pageTitle: 'Analytics',
            breadcrumb: [
            {
                text: computed(() => i18nT(`Dashboard`)),
                to: '/re/dashboard'
            }, {
                text: computed(() => i18nT(`Analytics`)),
                active: true
            }],
        },
    },
    {
        path: '/re/dashboard',
        name: 're_dashboard',
        component: () =>
            import ('@/views/dashboard/re_Dashboard.vue'),
        meta: {
            pageTitle: computed(() => i18nT(`Dashboard`)),
            breadcrumb: [{
                text: computed(() => i18nT(`Dashboard`)),
                active: true
            }],
        },
    },
    {
        path: '/en/dashboard',
        name: 'en_dashboard',
        component: () =>
            import ('@/views/dashboard/en_Dashboard.vue'),
        meta: {
            pageTitle: computed(() => i18nT(`Dashboard`)),
            breadcrumb: [ {
                text: computed(() => i18nT(`Dashboard`)),
                active: true
            }],
        },
    },
    {
        path: '/hr/dashboard',
        name: 'hr_dashboard',
        component: () =>
            import ('@/views/dashboard/hr_Dashboard.vue'),
        meta: {
            pageTitle: computed(() => i18nT(`Dashboard`)),
            breadcrumb: [ {
                text: computed(() => i18nT(`Dashboard`)),
                active: true
            }],
        },
    },
    {
        path: '/on/dashboard',
        name: 'on_dashboard',
        component: () =>
            import ('@/views/dashboard/on_Dashboard.vue'),
        meta: {
            pageTitle: computed(() => i18nT(`Dashboard`)),
            breadcrumb: [ {
                text: computed(() => i18nT(`Dashboard`)),
                active: true
            }],
        },
    },
    {
        path: '/ac/dashboard',
        redirect: '/ac/finances',
    },
];
