/* eslint-disable */
import {computed} from "@vue/composition-api";
import VueCompositionApi from '@vue/composition-api'
import { i18n } from '@/libs/i18n';
import { useUtils as useI18nUtils } from '@core/libs/i18n';
const { i18n: i18nFunc } = useI18nUtils();
import Vue from "vue";
Vue.use(VueCompositionApi)

function i18nT(str) {
	return i18nFunc(str);
}

export default [
	{
		path: '/admin/clients',
		name: 'admin-clients',
		component: () => import('@/views/admin/ClientList.vue'),
		meta: {
			layout: 'full',
			pageTitle: computed(() => i18nT(`Dashboard`)),
			breadcrumb: [
				{
					text: computed(() => i18nT(`Client List`)),
					active: true,
				},
			],
		},
	},
	{
		path: '/admin/employee',
		name: 'admin-employee',
		component: () => import('@/views/admin/AdminEmployeeList.vue'),
		meta: {
			layout: 'full',
			pageTitle: computed(() => i18nT(`Dashboard`)),
			breadcrumb: [
				{
					text: computed(() => i18nT(`Employee List`)),
					active: true,
				},
			],
		},
	},
	{
		path: '/admin/staff',
		name: 'admin-staff',
		component: () => import('@/views/admin/AdminStaffList.vue'),
		meta: {
			layout: 'full',
			pageTitle: computed(() => i18nT(`Dashboard`)),
			breadcrumb: [
				{
					text: computed(() => i18nT(`Staff List`)),
					active: true,
				},
			],
		},
	},

]
