import Vue from 'vue';
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue';
import VueCompositionAPI from '@vue/composition-api';
import moment from 'moment';
import { get } from 'lodash';
import { i18n } from '@/libs/i18n';
import router from './router';
import store from './store';
import App from './App.vue';
import useAuth from '@/auth/useAuth';
// Global Components
import './global-components';
import { useUtils as useI18nUtils } from '@core/libs/i18n';
// 3rd party plugins
import '@axios';

import '@/libs/portal-vue';
import '@/libs/clipboard';
import '@/libs/toastification';
import '@/libs/sweet-alerts';
import '@/libs/vue-select';
import VueGtag from "vue-gtag";

// Icons
import { library } from '@fortawesome/fontawesome-svg-core';
import {far} from '@fortawesome/free-regular-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';


//Crisp Chat
import CrispChat from '@dansmaculotte/vue-crisp-chat'

Vue.use(CrispChat, {
    websiteId: '039e5868-2c3a-4af8-bbf1-d828c705e765',
    disabled: false,
    hideOnLoad: false
})

//Phone input
import { MazBtn, MazPhoneNumberInput } from 'maz-ui'
import axios from "axios";
import {API_KEY, BASE_URL} from "@core/utils/constants";

Vue.use(MazBtn)
Vue.use(MazPhoneNumberInput)

library.add(fas);
library.add(fab);
library.add(far);

Vue.component('font-awesome-icon', FontAwesomeIcon);

// BSV Plugin Registration
Vue.use(ToastPlugin);
Vue.use(ModalPlugin);

// Composition API
Vue.use(VueCompositionAPI);

//Moment JS
Vue.use(require('vue-moment'));

//Google Analytics
Vue.use(VueGtag,{
    config: { id: "G-3CCV35L99E" },
},router);



// Feather font icon - For form-wizard
// * Shall remove it if not using font-icons of feather-icons - For form-wizard
require('@core/assets/fonts/feather/iconfont.css'); // For form-wizard

require('@core/scss/core.scss');
// import assets styles
require('@/assets/scss/style.scss');

Vue.config.productionTip = false;
const betaFeature = process.env.VUE_APP_BETA_FEATURE === 'true';
const { i18n: i18nFunc } = useI18nUtils();
Vue.mixin({
    data(){
        return{
            betaFeature
        }
    },
    methods: {
        changeLang(whichLocale = 'en') {
            // Check if the locale messages for the desired language are already loaded
            if (i18n.messages[whichLocale] && Object.keys(i18n.messages[whichLocale]).length > 1) {
                i18n.locale = whichLocale;
                this.langLoaded = true;
                return; // Exit the method early
            }

            store.commit('app/REQUEST_PENDING');
            axios.get(`${BASE_URL}system/locales?lang=` + whichLocale, {
                headers: {
                    'Api-Key': API_KEY,
                },
            }).then(({ data }) => {
                store.commit('app/REQUEST_DONE');
                const locales = Object.keys(data);

                locales.forEach((locale) => {
                    i18n.setLocaleMessage(locale, data[locale]);
                });

                i18n.locale = whichLocale;
                this.langLoaded = true;

            }).catch(ex => {
                store.commit('app/REQUEST_DONE');
                console.log(ex);
            });
        },

        i18nT: (str) => {
            return i18nFunc(str);
        },
        successMessage: (res) => {
            return (
                get(res, 'data.message') || get(res, 'message') || this.i18nT(`Success`)
            );
        },
        iCan: (module, perm) => {
            return useAuth.iCan(module, perm)
        },
        truncateStr: function(data='', num) {
            if(!data) return ''
            const reqdString = data
                .split(' ')
                .slice(0, num)
                .join(' ')
            return data.length > reqdString.length ? reqdString + '...' : reqdString
        },
        empty(value) {
            return (
                value === undefined ||
                value === null ||
                value === '' ||
                value === 0 ||
                value === false ||
                (Array.isArray(value) && value.length === 0) ||
                (typeof value === 'object' && Object.keys(value).length === 0)
            );
        },
        responsiveColumns(table, conditions) {
            const windowWidth = this.$store.getters['app/currentWindowWidth']
            let condition = []
            let filteredTable = table
            for(let i = conditions.length - 1; i >= 0; i--) {
                if(conditions[i].resolution > windowWidth) {
                    condition = conditions[i].columns
                    break
                }
            }
            if(condition.length > 0) {
                filteredTable = table.filter(cell => {
                   return !condition.includes(cell.key)
                })
            }
            return filteredTable
        },
        errorMessage: (err) => {
            return (
                get(err, 'response.data.message') || i18nFunc(`An error has occurred`)
            );
        },
    },
});

Vue.filter('date', (value) => {
    if(!value) return '';
    let userFormat = useAuth.getDateFormat();
    let dateFormat = userFormat && userFormat !== 'null' ? userFormat : 'DD/MM/YYYY';
    const formatted = moment(new Date(value)).format(dateFormat);
    return formatted;
});

Vue.filter('fileSize', (value) => {
    if (value < 1024 * 1024) {
        // If the file size is less than 1MB, display in KB
        const fileSize = value / 1024;
        const formatted = fileSize.toFixed(2) + ' KB';
        return formatted;
    } else {
        // If the file size is 1MB or greater, display in MB
        const fileSize = value / 1024 / 1024;
        const formatted = fileSize.toFixed(2) + ' MB';
        return formatted;
    }
});


Vue.filter('capFirst', (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1)
})

Vue.filter('money', (value) => {
    return parseFloat(value).toFixed(2); // 2 decimal places
});

Vue.filter('currency', (value) => {
    const currency = value.currency || 'EUR';
    const locale = value.locale || 'en-US';
    const formatted = new Intl.NumberFormat(locale, { style: 'currency', currency: currency }).format(value.amount);
    return formatted;
});

Vue.filter('truncate', (value, limit) => {
    if (value.length > limit) {
        value = value.substring(0, limit) + '...';
    }
    return value;
});

Vue.filter('time', (value) => {
    const dateFormat = 'HH:mm';
    const formatted = moment(new Date(value)).format(dateFormat);
    return formatted;
});


new Vue({
    router,
    store,
    i18n,
    render: (h) => h(App),
}).$mount('#app');
