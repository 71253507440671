import useAuth from '@/auth/useAuth';
const currentSoftware = useAuth.getCurrentSoftware();

import {computed} from "@vue/composition-api";
import VueCompositionApi from '@vue/composition-api'
import { useUtils as useI18nUtils } from '@core/libs/i18n';
const { i18n: i18nFunc } = useI18nUtils();
import Vue from "vue";

Vue.use(VueCompositionApi)

function i18nT(str) {
	return i18nFunc(str);
}

export default [
	{
		path: '/:software/employees',
		name: 'employees',
		beforeEnter: (to, from, next) => {
			to.meta.breadcrumb = [
				{
					text: computed(() => i18nT(`Employees`)),
					to: `/`+to.params.software+`/employees`,
				},
				{
					text: computed(() => i18nT(`Employee list`)),
					active: true,
				},
			];
			next();
		},
		component: () => import('@/views/common/Employees/EmployeeList.vue'),
		meta: {
			module: 'employees',
			pageTitle: computed(() => i18nT(`Employee list`)),
			breadcrumb: [
				{
					text: computed(() => i18nT(`Employees`)),
					to: `/`+currentSoftware+`/employees`,
				},
				{
					text: computed(() => i18nT(`Employee list`)),
					active: true,
				},
			],
		},
	},
	{
		path: '/:software/employees/view/:id',
		name: 'view-employee',
		component: () => import('@/views/common/Employees/EmployeeDetail.vue'),
		beforeEnter: (to, from, next) => {
			to.meta.breadcrumb = [
				{
					text: computed(() => i18nT(`Employees`)),
					to: `/${to.params.software}/employees`,
				},
				{
					text: computed(() => i18nT(`Employee list`)),
					to: `/${to.params.software}/employees`,
				},
				{
					text: computed(() => i18nT(`Employee`)),
					active: true,
				},
			],
			next();
		},
		meta: {
			module: 'employees',
			navActiveLink: 'employees',
			pageTitle: computed(() => i18nT(`Employee`)),
			breadcrumb: [
				{
					text: computed(() => i18nT(`Employees`)),
					to: `/${currentSoftware}/employees`,
				},
				{
					text: computed(() => i18nT(`Employee list`)),
					to: `/${currentSoftware}/employees`,
				},
				{
					text: computed(() => i18nT(`Employee`)),
					active: true,
				},
			],
		},
	},
	{
		path: '/re/applications/view/:id',
		name: 'view-application',
		component: () =>
			import('@/views/recruiting/Candidates/CandidateDetail.vue'),
		meta: {
			module: 'applications',
			navActiveLink: 'applications',
			pageTitle: computed(() => i18nT(`Applications`)),
			breadcrumb: [
				{
					text: computed(() => i18nT(`Application list`)),
					to: '/re/applications/ALL',
				},
				{
					text: computed(() => i18nT(`Application`)),
					active: true,
				},
			],
		},
	},
	{
		path: '/:software/create-employee',
		name: 'create-employee',
		component: () => import('@/views/common/Employees/EditEmployee.vue'),
		beforeEnter: (to, from, next) => {
			to.meta.breadcrumb = [
				{
					text: computed(() => i18nT(`Employees`)),
					to: `/${to.params.software}/employees`,
				},
				{
					text: computed(() => i18nT(`Employee list`)),
					to: `/${to.params.software}/employees`,
				},
				{
					text: computed(() => i18nT(`Employee`)),
					active: true,
				},
			],

			next();
		},
		meta: {
			module: 'employees',
			mode: 'write',
			navActiveLink: 'employees',
			pageTitle: computed(() => i18nT(`Employee`)),
			breadcrumb: [
				{
					text: computed(() => i18nT(`Employees`)),
					to: `/${currentSoftware}/employees`,
				},
				{
					text: computed(() => i18nT(`Employee list`)),
					to: `/${currentSoftware}/employees`,
				},
				{
					text: computed(() => i18nT(`Employee`)),
					active: true,
				},
			],
		},
	},
	{
		path: '/:software/edit-employee/:id',
		name: 'edit-employee',
		component: () => import('@/views/common/Employees/EditEmployee.vue'),
		beforeEnter: (to, from, next) => {
			to.meta.breadcrumb = [
				{
					text: computed(() => i18nT(`Employees`)),
					to: `/${to.params.software}/employees`,
				},
				{
					text: computed(() => i18nT(`Employee list`)),
					to: `/${to.params.software}/employees`,
				},
				{
					text: computed(() => i18nT(`Employee`)),
					active: true,
				},
			],

			next();
		},
		meta: {
			module: 'employees',
			mode: 'write',
			navActiveLink: 'employees',
			pageTitle: computed(() => i18nT(`Employee`)),
			beforeEnter: (to, from, next) => {
				to.meta.breadcrumb = [
					{
						text: computed(() => i18nT(`Employees`)),
						to: `/${to.params.software}/employees`,
					},
					{
						text: computed(() => i18nT(`Employee list`)),
						to: `/${to.params.software}/employees`,
					},
					{
						text: computed(() => i18nT(`Employee`)),
						active: true,
					},
				],

				next();
			},
			breadcrumb: [
				{
					text: computed(() => i18nT(`Employees`)),
					to: `/${currentSoftware}/employees`,
				},
				{
					text: computed(() => i18nT(`Employee list`)),
					to: `/${currentSoftware}/employees`,
				},
				{
					text: computed(() => i18nT(`Employee`)),
					active: true,
				},
			],
		},
	},
	{
		path: '/:software/employee-groups',
		name: 'employee-groups',
		component: () => import('@/views/common/EmployeeGroups/EmployeeGroups.vue'),
		beforeEnter: (to, from, next) => {
			to.meta.breadcrumb = [
				{
					text: computed(() => i18nT(`Employees`)),
					to: `/${to.params.software}/employees`,
				},
				{
					text: computed(() => i18nT(`Teams`)),
					active: true,
				},
			],

			next();
		},
		meta: {
			module: 'employees',
			pageTitle: computed(() => i18nT(`Teams`)),
			breadcrumb: [
				{
					text: computed(() => i18nT(`Employees`)),
					to: `/${currentSoftware}/employees`,
				},
				{
					text: computed(() => i18nT(`Teams`)),
					active: true,
				},
			],
		},
	},
	{
		path: '/:software/edit-employee-group/:id',
		name: 'edit-employee-group',
		component: () =>
			import('@/views/common/EmployeeGroups/EditEmployeeGroup.vue'),
		beforeEnter: (to, from, next) => {
			to.meta.breadcrumb = [
				{
					text: computed(() => i18nT(`Employees`)),
					to: `/${to.params.software}/employees`,
				},
				{
					text: computed(() => i18nT(`Teams`)),
					to: `/${to.params.software}/employee-groups`,
				},
				{
					text: computed(() => i18nT(`Team`)),
					active: true,
				},
			],
			next();
		},
		meta: {
			module: 'employees',
			mode: 'write',
			navActiveLink: 'employee-groups',
			pageTitle: 'Edit team',
			breadcrumb: [
				{
					text: computed(() => i18nT(`Employees`)),
					to: `/${currentSoftware}/employees`,
				},
				{
					text: computed(() => i18nT(`Teams`)),
					to: `/${currentSoftware}/employee-groups`,
				},
				{
					text: computed(() => i18nT(`Team`)),
					active: true,
				},
			],
		},
	},
	{
		path: '/:software/view-employee-group/:id',
		name: 'view-employee-group',
		component: () =>
			import('@/views/common/EmployeeGroups/ViewEmployeeGroup.vue'),
		beforeEnter: (to, from, next) => {
			to.meta.breadcrumb = [
				{
					text: computed(() => i18nT(`Employees`)),
					to: `/${to.params.software}/employees`,
				},
				{
					text: computed(() => i18nT(`Teams`)),
					to: `/${to.params.software}/employee-groups`,
				},
				{
					text: computed(() => i18nT(`Team`)),
					active: true,
				},
			],
			next();
		},
		meta: {
			module: 'employees',
			navActiveLink: 'employee-groups',
			pageTitle: computed(() => i18nT(`View team`)),
			breadcrumb: [
				{
					text: computed(() => i18nT(`Employees`)),
					to: `/${currentSoftware}/employees`,
				},
				{
					text: computed(() => i18nT(`Teams`)),
					to: `/${currentSoftware}/employee-groups`,
				},
				{
					text: computed(() => i18nT(`Team`)),
					active: true,
				},
			],
		},
	},
	{
		path: '/:software/create-employee-group',
		name: 'create-employee-group',
		component: () =>
			import('@/views/common/EmployeeGroups/EditEmployeeGroup.vue'),
		beforeEnter: (to, from, next) => {
			to.meta.breadcrumb = [
				{
					text: computed(() => i18nT(`Employees`)),
					to: `/${to.params.software}/employee-groups`,
				},
				{
					text: computed(() => i18nT(`Teams`)),
					to: `/${to.params.software}/employee-groups`,
				},
				{
					text: computed(() => i18nT(`Team`)),
					active: true,
				},
			],
			next();
		},
		meta: {
			module: 'employees',
			mode: 'write',
			navActiveLink: 'employee-groups',
			pageTitle: 'Create team',
			breadcrumb: [
				{
					text: computed(() => i18nT(`Teams`)),
					to: `/${currentSoftware}/employee-groups`,
				},
				{
					text: computed(() => i18nT(`Teams`)),
					to: `/${currentSoftware}/employee-groups`,
				},
				{
					text: computed(() => i18nT(`Team`)),
					active: true,
				},
			],
		},
	},
];
