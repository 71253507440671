import authDefaultConfig from './authDefaultConfig';
import { BASE_URL, API_KEY } from '@core/utils/constants';
import useAuth from '@/auth/useAuth';
import {useUtils as useI18nUtils} from "@core/libs/i18n";
const { i18n } = useI18nUtils();
function i18nT(str){
  return i18n(str)
};

export default class AuthService {
  // Will be used by this service for making API calls
  axiosIns = null;

  // authConfig <= Will be used by this service
  authConfig = { ...authDefaultConfig };

  // For Refreshing Token
  isAlreadyFetchingAccessToken = false;

  // For Refreshing Token
  subscribers = [];

  dict = {
    re: 1,
    on: 2,
    hr: 3,
    en: 4,
    ac: 5,
    //CANDIDATE AND EMPLOYEE CASE
    ca: 1,
    em: 2,
  };

  constructor(axiosIns, authOverrideConfig) {
    this.axiosIns = axiosIns;
    this.authConfig = { ...this.authConfig, ...authOverrideConfig };

    // Request Interceptor
    this.axiosIns.interceptors.request.use(
      (config) => {
        // Get token from localStorage
        const accessToken = this.getToken();

        // If token is present add it to request's Authorization Header
        if (accessToken) {
          // eslint-disable-next-line no-param-reassign
          config.headers['Auth-Key'] = accessToken;
        }
        config.headers['Api-Key'] = API_KEY;

        const url = window.location.href;
        // Create a URLSearchParams object from the current URL
        const params = new URLSearchParams(url);
        // Get the value of the 'lang' parameter
        const langParam = params.get('lang');

        if (langParam && config && config.data) {
          if (config.data instanceof FormData) {
            config.data.append(
                'lang',
                langParam
            );
          }else {
            config.data['lang'] = langParam;
          }
        }

        if (config && config.method == 'post' && accessToken) {
          if (config.data instanceof FormData) {
            if (!config.data.get('company_id')) {
              config.data.append(
                'company_id',
                useAuth.getCurWorkspace() ? useAuth.getCurWorkspace().Id : ''
              );
            }
            if (!config.data.get('software_id')) {
              config.data.append('software_id', useAuth.getCurrentSoftwareId());
            }

            const keys = config.data.keys();
            for (var key of keys) {
              if (
                config.data.get(key) === 'undefined' ||
                config.data.get(key) === 'null'
              ) {
                config.data.set(key, '');
              }
            }
          } else if (config.data) {
            if (!config.data['company_id'])
              config.data['company_id'] = useAuth.getCurWorkspace()
                ? useAuth.getCurWorkspace().Id
                : '';
            if (!config.data['software_id'])
              config.data['software_id'] = useAuth.getCurrentSoftwareId();

            const keys = Object.keys(config.data);
            keys.forEach((key) => {
              if (config.data[key] === undefined) {
                config.data[key] = '';
              }
            });
          }
        }

        if (config.method == 'get' && accessToken) {
          config.url += `${
            config.url.indexOf('?') > -1 ? '&' : '?'
          }company_id=${
            useAuth.getCurWorkspace() ? useAuth.getCurWorkspace().Id : ''
          }&software_id=${useAuth.getCurrentSoftwareId()}`;
        }

        if (config.method == 'delete' && accessToken) {
          config.url += `${
            config.url.indexOf('?') > -1 ? '&' : '?'
          }company_id=${
            useAuth.getCurWorkspace() ? useAuth.getCurWorkspace().Id : ''
          }&software_id=${useAuth.getCurrentSoftwareId()}`;
        }

        return config;
      },
      (error) => Promise.reject(error)
    );

    // Add request/response interceptor
    this.axiosIns.interceptors.response.use(
      (response) => response,
      (error) => {
        // const { config, response: { status } } = error
        const { config, response } = error;
        const originalRequest = config;

        // if (status === 401) {
        if (response && response.status === 401) {
          if (!this.isAlreadyFetchingAccessToken) {
            this.isAlreadyFetchingAccessToken = true;
            // this.refreshToken().then((r) => {
            //   this.isAlreadyFetchingAccessToken = false;
            //
            //   // Update accessToken in localStorage
            //   this.setToken(r.data.accessToken);
            //   this.setRefreshToken(r.data.refreshToken);
            //
            //   this.onAccessTokenFetched(r.data.accessToken);
            // });
          }
          const retryOriginalRequest = new Promise((resolve) => {
            this.addSubscriber((accessToken) => {
              // Make sure to assign accessToken according to your response.
              // Check: https://pixinvent.ticksy.com/ticket/2413870
              // Change Authorization header
              originalRequest.headers.Authorization = `${this.authConfig.tokenType} ${accessToken}`;
              resolve(this.axiosIns(originalRequest));
            });
          });
          return retryOriginalRequest;
        }
        return Promise.reject(error);
      }
    );
  }

  iCan(menuModule, mode = 'read') {
    let userType = this.getUserType();
    if(userType !== 'Staff' || menuModule == 'always') {
      return true;
    }
    let permissions = this.getUserPermissions();
    let foundModule = null;

    if (permissions && permissions !== 'undefined') {
      let permissionsArray = Object.values(permissions);
      for (let i = 0; i < permissionsArray.length; i++) {
        if(!foundModule){

          let moduleKeys = Object.keys(permissionsArray[i].modules);
          for (let j = 0; j < moduleKeys.length; j++) {
            if(moduleKeys[j] == menuModule){
              foundModule = permissionsArray[i].modules[moduleKeys[j]];
              return permissionsArray[i].modules[moduleKeys[j]].acl[mode];
            }
          }
        }
      }
    }

    return false;
  }

  hasRole(roles) {
    let userType = this.getUserType();
    if(roles.indexOf(userType) > -1) {
      return true;
    }else {
      return false;
    }
  }

  onAccessTokenFetched(accessToken) {
    this.subscribers = this.subscribers.filter((callback) =>
      callback(accessToken)
    );
  }

  addSubscriber(callback) {
    this.subscribers.push(callback);
  }

  getToken() {
    return localStorage.getItem(this.authConfig.storageTokenKeyName);
  }

  getRefreshToken() {
    return localStorage.getItem(this.authConfig.storageRefreshTokenKeyName);
  }

  setToken(value) {
    localStorage.setItem(this.authConfig.storageTokenKeyName, value);
  }

  setRefreshToken(value) {
    localStorage.setItem(this.authConfig.storageRefreshTokenKeyName, value);
  }

  login(...args) {
    return this.axiosIns.post(this.authConfig.loginEndpoint, ...args);
  }

  register(...args) {
    return this.axiosIns.post(this.authConfig.registerEndpoint, ...args);
  }

  setCurWorkspace(value) {
    localStorage.setItem(
      this.authConfig.storageCurWorkspaceKeyName,
      JSON.stringify(value)
    );
  }

  getCurWorkspace() {
    return JSON.parse(
      localStorage.getItem(this.authConfig.storageCurWorkspaceKeyName, '{}')
    );
  }

  setWorkspaces(value) {
    localStorage.setItem(
      this.authConfig.storageWorkspacesKeyName,
      JSON.stringify(value)
    );
  }

  getWorkspaces() {
    return JSON.parse(
      localStorage.getItem(this.authConfig.storageWorkspacesKeyName, '{}')
    );
  }

  setProfile(value) {
    localStorage.setItem(
      this.authConfig.storageProfileKeyName,
      JSON.stringify(value)
    );
  }

  setColumnPreferences(listName, preferences) {
    localStorage.setItem(`list-${listName}`, JSON.stringify(preferences));
  }
  getColumnPreferences(listName) {
    return JSON.parse(localStorage.getItem(`list-${listName}`, '{}'));
  }

  getProfile() {
    return JSON.parse(
      localStorage.getItem(this.authConfig.storageProfileKeyName, '{}')
    );
  }

  setUserType(value) {
    localStorage.setItem(
      this.authConfig.storageUserTypeKeyName,
      JSON.stringify(value)
    );
  }
  getUserType() {
    return JSON.parse(
      localStorage.getItem(this.authConfig.storageUserTypeKeyName, '{}')
    );
  }
  setUserPermissions(value) {
    localStorage.setItem(
        this.authConfig.storageUserPermissions,
        JSON.stringify(value)
    );
  }
  getUserPermissions() {
    return JSON.parse(
        localStorage.getItem(this.authConfig.storageUserPermissions, '{}')
    );
  }
  setUserId(value) {
    localStorage.setItem(
        this.authConfig.userId,
        JSON.stringify(value)
    );
  }
  getUserId() {
    return JSON.parse(
        localStorage.getItem(this.authConfig.userId, '{}')
    );
  }
  getProperUserId () {
    let userObj = JSON.parse(localStorage.getItem(this.authConfig.storageProfileKeyName, '{}'))

    return userObj.Id;
  }
  setDateFormat(value) {
    localStorage.setItem(this.authConfig.storageUserDateFormatKeyName, value);
  }

  getDateFormat() {
    return localStorage.getItem(
      this.authConfig.storageUserDateFormatKeyName,
      ''
    );
  }

  getLocale() {
    return localStorage.getItem(
        this.authConfig.storageLocale,
        ''
    );
  }

  getDateFormatFlat() {
    return localStorage.getItem(
      this.authConfig.storageUserDateFormatFlatKeyName,
      ''
    );
  }

  setDateFormatFlat(value) {
    localStorage.setItem(
      this.authConfig.storageUserDateFormatFlatKeyName,
      value
    );
  }

  setLocale(value) {
    localStorage.setItem(
        this.authConfig.storageLocale,
        value
    );
  }

  setSoftwares(value) {
    localStorage.setItem(
      this.authConfig.storageSoftwaresKeyName,
      JSON.stringify(value)
    );
  }

  getSoftwares() {
    return JSON.parse(
      localStorage.getItem(this.authConfig.storageSoftwaresKeyName, '{}')
    );
  }

  setCurrentSoftware(value) {
    localStorage.setItem(
      this.authConfig.storageCurrentSoftwareKeyName,
      value + ''
    );
  }

  getCurrentSoftware() {
    const softwares = ['en', 'hr', 'on', 're', 'ac'];
    const urlSoftware = window.location.pathname.split('/')[1];
    if (softwares.includes(urlSoftware)) {
      return urlSoftware;
    } else {
      return localStorage.getItem(
        this.authConfig.storageCurrentSoftwareKeyName,
        ''
      );
    }
  }
  getCurrentSoftwareId() {
    const software = this.getCurrentSoftware();

    return this.dict[software];
  }

  getCurrentSoftName() {
    const titles = {
      re: i18nT(`Recruiting`),
      on: i18nT(`Onboarding`),
      hr: i18nT(`Human Resources`),
      en: i18nT(`Engagement`),
      ca: i18nT(`Candidate`),
      em: i18nT(`Employee`),
      ac: i18nT(`Accounting`),
    };
    return titles[this.getCurrentSoftware()];
  }

  setDontRemind(payload){
    localStorage.setItem('dontRemind' + payload.name, payload.value);
  }
  getDontRemind(name){
    return localStorage.getItem('dontRemind' + name);
  }

  refreshToken() {
    // return this.axiosIns.post(this.authConfig.refreshEndpoint, {
    //   refreshToken: this.getRefreshToken(),
    // });
    return false;
  }

  resetUser() {
    // Clear local storage
    this.setToken(null);
    this.setRefreshToken(null);
    this.setCurWorkspace(null);
    this.setWorkspaces(null);
    this.setProfile(null);
    this.setUserType(null);
    this.setUserPermissions(null);
    this.setUserId(null);
    this.setDateFormat(null);
    this.setDateFormatFlat(null);
    // this.setLocale(null);
    this.setSoftwares(null);
    this.setCurrentSoftware(null);
  }

}
