/* eslint-disable */
import {computed} from "@vue/composition-api";
import VueCompositionApi from '@vue/composition-api'
import { i18n } from '@/libs/i18n';
import { useUtils as useI18nUtils } from '@core/libs/i18n';
const { i18n: i18nFunc } = useI18nUtils();
import Vue from "vue";
Vue.use(VueCompositionApi)

function i18nT(str) {
  return i18nFunc(str);
}

export default [
  {
    path: '/en/surveys',
    name: 'surveys',
    component: () => import('@/views/engagement/Surveys/SurveyList.vue'),
    meta: {
      module: 'surveys',
      pageTitle: computed(() => i18nT(`Surveys`)),
      breadcrumb: [
        {
          text: computed(() => i18nT(`Surveys`)),
          to: '/en/surveys',
        },
        {
          text: computed(() => i18nT(`Survey List`)),
          active: true,
        },
      ],
    },
  },
  {
    path: '/en/surveys/edit/:id',
    name: 'create-edit-survey',
    component: () => import('@/views/engagement/Surveys/EditSurvey.vue'),
    meta: {
      module: 'surveys',
      mode: 'write',
      navActiveLink: 'surveys',
      pageTitle: computed(() => i18nT(`Edit Survey`)),
      breadcrumb: [
        {
          text: computed(() => i18nT(`Home`)),
          to: '/',
        },
        {
          text: computed(() => i18nT(`Surveys`)),
          to: '/en/surveys',
        },
        {
          text: computed(() => i18nT(`Survey List`)),
          to: '/en/surveys',
        },
        {
          text: computed(() => i18nT(`Survey`)),
          active: true,
        },
      ],
    },
  },
  {
    path: '/en/surveys/view/:id',
    name: 'view-survey',
    component: () => import('@/views/engagement/Surveys/ViewSurvey.vue'),
    meta: {
      module: 'surveys',
      navActiveLink: 'surveys',
      pageTitle: computed(() => i18nT(`View Survey`)),
      breadcrumb: [
        {
          text: computed(() => i18nT(`Home`)),
          to: '/',
        },
        {
          text: computed(() => i18nT(`Surveys`)),
          to: '/en/surveys',
        },
        {
          text: computed(() => i18nT(`Survey List`)),
          to: '/en/surveys',
        },
        {
          text: computed(() => i18nT(`Survey`)),
          active: true,
        },
      ],
    },
  },
  {
    path: '/en/surveys/result/:id',
    name: 'result-survey',
    component: () => import('@/views/engagement/Surveys/ResultSurvey.vue'),
    meta: {
      module: 'surveys',
      navActiveLink: 'surveys',
      pageTitle: computed(() => i18nT(`Survey Result`)),
      breadcrumb: [
        {
          text: computed(() => i18nT(`Home`)),
          to: '/',
        },
        {
          text: computed(() => i18nT(`Surveys`)),
          to: '/en/surveys',
        },
        {
          text: computed(() => i18nT(`Survey List`)),
          to: '/en/surveys',
        },
        {
          text: computed(() => i18nT(`Survey Result`)),
          active: true,
        },
      ],
    },
  },
  {
    path: '/en/survey-templates',
    name: 'survey-templates',
    component: () => import('@/views/engagement/Surveys/SurveyList.vue'),
    meta: {
      module: 'surveys',
      template: 1,
      pageTitle: computed(() => i18nT(`Survey templates`)),
      breadcrumb: [
        {
          text: computed(() => i18nT(`Home`)),
          to: '/',
        },
        {
          text: computed(() => i18nT(`Surveys`)),
          to: '/en/surveys',
        },
        {
          text: computed(() => i18nT(`Survey templates`)),
          active: true,
        },
      ],
    },
  },
  {
    path: '/en/surveys-questions',
    name: 'survey-questions',
    component: () => import('@/views/engagement/Questions/QuestionList.vue'),
    meta: {
      module: 'surveys',
      pageTitle: computed(() => i18nT(`Survey Questions`)),
      breadcrumb: [
        {
          text: computed(() => i18nT(`Home`)),
          to: '/',
        },
        {
          text: computed(() => i18nT(`Surveys`)),
          to: '/en/surveys',
        },
        {
          text: computed(() => i18nT(`Survey Question List`)),
          active: true,
        },
      ],
    },
  },
  {
    path: '/en/surveys-questions/edit/:id',
    name: 'create-edit-question',
    component: () => import('@/views/engagement/Questions/EditQuestion.vue'),
    meta: {
      module: 'surveys',
      mode: 'write',
      navActiveLink: 'survey-questions',
      pageTitle: computed(() => i18nT(`Edit Survey Question`)),
      breadcrumb: [
        {
          text: computed(() => i18nT(`Home`)),
          to: '/',
        },
        {
          text: computed(() => i18nT(`Surveys`)),
          to: '/en/surveys',
        },
        {
          text: computed(() => i18nT(`Survey Question List`)),
          to: '/en/surveys-questions',
        },
        {
          text: computed(() => i18nT(`Survey Question`)),
          active: true,
        },
      ],
    },
  },
  {
    path: '/en/suggestions',
    name: 'suggestions',
    component: () =>
      import('@/views/engagement/Suggestions/SuggestionList.vue'),
    meta: {
      module: 'suggestions',
      pageTitle: computed(() => i18nT(`Suggestions`)),
      breadcrumb: [
        {
          text: computed(() => i18nT(`Suggestion list`)),
          active: true,
        },
      ],
    },
  },
  {
    path: '/en/suggestions/edit/:id',
    name: 'create-edit-suggestion',
    component: () =>
      import('@/views/engagement/Suggestions/EditSuggestion.vue'),
    meta: {
      module: 'suggestions',
      mode: 'write',
      navActiveLink: 'suggestions',
      pageTitle: computed(() => i18nT(`Edit suggestion`)),
      breadcrumb: [
        {
          text: computed(() => i18nT(`Suggestion list`)),
          to: '/en/suggestions',
        },
        {
          text: computed(() => i18nT(`Suggestion`)),
          active: true,
        },
      ],
    },
  },
  {
    path: '/en/suggestions/view/:id',
    name: 'view-suggestion',
    component: () =>
      import('@/views/engagement/Suggestions/ViewSuggestion.vue'),
    meta: {
      module: 'suggestions',
      navActiveLink: 'suggestions',
      pageTitle: computed(() => i18nT(`View suggestion`)),
      breadcrumb: [
        {
          text: computed(() => i18nT(`Suggestion list`)),
          to: '/en/suggestions',
        },
        {
          text: computed(() => i18nT(`Suggestion`)),
          active: true,
        },
      ],
    },
  },
]

/* eslint-enable */
