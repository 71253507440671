import {computed} from "@vue/composition-api";
import VueCompositionApi from '@vue/composition-api'
import { i18n } from '@/libs/i18n';
import { useUtils as useI18nUtils } from '@core/libs/i18n';
const { i18n: i18nFunc } = useI18nUtils();
import Vue from "vue";
Vue.use(VueCompositionApi)

function i18nT(str) {
  return i18nFunc(str);
}

/* eslint-disable */
export default [
  {
    path: '/on/onboarding/:id',
    name: 'onboarding',
    component: () => import('@/views/onboarding/onboarding/Onboarding.vue'),
    meta: {
      module: 'onboarding',
      onboarding: 1,
      pageTitle: computed(() => i18nT(`Onboarding`)),
      breadcrumb: [
        {
          text: computed(() => i18nT(`Onboarding list`)),
          active: true,
        },
      ],
    },
  },
  {
    path: '/on/offboarding/:id',
    name: 'offboarding',
    component: () => import('@/views/onboarding/offboarding/Offboarding.vue'),
    meta: {
      module: 'offboarding',
      onboarding: 0,
      pageTitle: computed(() => i18nT(`Offboarding`)),
      breadcrumb: [
        {
          text: computed(() => i18nT(`Offboarding list`)),
          to: '/on/offboarding',
        },
        {
          text: computed(() => i18nT(`Offboarding`)),
          active: true,
        },
      ],
    },
  },
  {
    path: '/on/job-offers/view/:id',
    name: 'on-view-job-offer',
    component: () => import('@/views/recruiting/JobOffers/ViewJobOffer.vue'),
    meta: {
      module: 'job-offers',
      navActiveLink: 'job-offers',
      pageTitle: computed(() => i18nT(`Job offer`)),
      breadcrumb: [
        {
          text: computed(() => i18nT(`Job offers`)),
        },
        {
          text: computed(() => i18nT(`Job offer`)),
          active: true,
        },
      ],
    },
  },
]

/* eslint-enable */
