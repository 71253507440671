import {computed} from "@vue/composition-api";
import VueCompositionApi from '@vue/composition-api'
import { i18n } from '@/libs/i18n';
import { useUtils as useI18nUtils } from '@core/libs/i18n';
const { i18n: i18nFunc } = useI18nUtils();
import Vue from "vue";
Vue.use(VueCompositionApi)

function i18nT(str) {
    return i18nFunc(str);
}

export default [{
        path: '/error-404',
        name: 'error-404',
        component: () =>
            import ('@/views/error/Error404.vue'),
        meta: {
            layout: 'full',
            resource: 'Auth',
            action: 'read',
            module: 'always'
        },
    },
    {
        path: '/login',
        name: 'auth-login',
        component: () =>
            import ('@/views/pages/authentication/Login.vue'),
        meta: {
            layout: 'full',
            resource: 'Auth',
            redirectIfLoggedIn: true,
        },
    },
    {
        path: '/register',
        name: 'auth-register',
        component: () =>
            import ('@/views/pages/authentication/Register.vue'),
        meta: {
            layout: 'full',
            resource: 'Auth',
            redirectIfLoggedIn: true,
        },
    },


    {
        path: '/forgot-password',
        name: 'auth-forgot-password',
        component: () =>
            import ('@/views/pages/authentication/ForgotPassword.vue'),
        meta: {
            layout: 'full',
            resource: 'Auth',
            redirectIfLoggedIn: true,
        },
    },
    {
        path: '/set-password',
        name: 'auth-set-password',
        component: () =>
            import ('@/views/pages/authentication/SetPassword.vue'),
        meta: {
            layout: 'full',
            resource: 'Auth',

        },
    },


    {
        path: '/not-authorized',
        name: 'misc-not-authorized',
        component: () =>
            import ('@/views/pages/miscellaneous/NotAuthorized.vue'),
        meta: {
            module: 'always',
            layout: 'full',
            resource: 'Auth',
        },
    },
    {
        path: '/under-maintenance',
        name: 'under-maintenance',
        component: () =>
            import ('@/views/pages/miscellaneous/UnderMaintenance.vue'),
        meta: {
            layout: 'full',
        },
    },
    {
        path: '/pages/miscellaneous/error',
        name: 'misc-error',
        component: () =>
            import ('@/views/pages/miscellaneous/Error.vue'),
        meta: {
            layout: 'full',
        },
    },

    {
        path: '/profile',
        name: 'pages-profile',
        component: () =>
            import ('@/views/pages/profile/Profile.vue'),
    },

]
