import Vue from "vue";


export default [{
        path: '/apps/calendar',
        name: 'apps-calendar',
        component: () =>
            import ('@/views/apps/calendar/Calendar.vue'),
        meta: {
            pageTitle: 'Calendar',
            breadcrumb: [ {
                text: 'Calendar',
                active: true,
            }],
        },
    },

];
