<template>
    <div
        v-if="false"
        class="command-container" :class="isRecording ? 'active' : ''">

        <div class="command-btn-wrapper" v-if="isLoggedIn">
            <b-button
                variant="outline-primary"
                @click="startRecordingOld"
                :disabled="inProgress"
                :id="`popover-1-left`"
                class="radius-100 rec-btn align-content-center align-items-center p-0"

            >
                <div ref="progressContainer" class="progress-container"></div>
                <font-awesome-icon v-if="!thinking"
                                   :icon="['fas', ico]"
                                   class="rec-icon"
                                   size="2x"/>

                <font-awesome-icon v-if="thinking"
                                   :icon="['fas', 'spinner']"
                                   class="rec-icon"
                                   spin
                                   size="2x"/>
            </b-button>
            <div ref="progressContainer" class="progress-container"></div>
        </div>

        <b-popover
            :target="`popover-1-left`"
            :placement="`left`"
            :title="i18nT(`Input a voice command`)"
            triggers="hover focus"
            class="box-sizing-border-box"
        >

            <div class="min-height-100 min-width-300 p-0">
                <transition-group
                    type="transition"
                    name="fade"
                    mode="out-in">

                    <div
                        v-if="messageState == 'default' || messageState == 'hasResponse'"
                        key="normal-message"
                    >
                        <p class="font-small-2" v-html="popOverMessage"></p>

                        <div
                            v-if="hasAction"
                            key="action-button"
                            class="w-100 text-center py-1">
                            <b-button
                                size="xs"
                                variant="primary"
                                class="mt-1"
                                @click="confirmAction"
                            >
                                {{i18nT(`Confirm action`)}}
                            </b-button>
                        </div>

                    </div>

                    <div
                        v-else-if="messageState == 'recording'"
                        key="recording-message"
                        class="w-100 text-primary text-center py-2">
                        <strong>{{i18nT(`Speak your message now...`)}}</strong>
                    </div>

                    <div
                        v-else-if="messageState == 'thinking'"
                        key="thinking"
                        class="w-100 text-primary text-center py-2">
                        <strong>{{i18nT(`Processing your request...`)}}</strong>
                    </div>

                    <div
                        v-else-if="messageState == 'actionSuccess'"
                        key="action-success-msg"
                        id="tempMessage"
                        ref="tempMessage"
                        class="w-100 text-success text-center py-3">
                        <strong>{{i18nT(`Action executed successfully!`)}}</strong>
                    </div>

                </transition-group>
            </div>


        </b-popover>

    </div>


</template>

<script>

import  {
    BButton,
    BPopover,
    //BProgress,
}   from "bootstrap-vue";
import axios from "axios";
import {API_KEY, BASE_URL} from "@core/utils/constants";
import ProgressBar from 'progressbar.js';
import useAuth from "@/auth/useAuth";

export default {
    name: "VoiceCommands",
    components: {
        BButton,
        BPopover,

    },
    data(){

        return {
            inProgress: false,
            thinking: false,
            audioBlob: null,
            isRecording: false,
            progressBar: null,
            mediaRecorder: null,
            showSuccess: false,
            result: {},
            popOverMessage: "The result from your voice command will be shown here. <br> <br> <b>Example:</b> <br> <i>\"Open the task view\"</i>",
            defaultMessage: "The result from your voice command will be shown here. <br> <br> <b>Example:</b> <br> <i>\"Open the task view\"</i>",
        }
    },
    computed: {
        ico() {
            return this.isRecording ? 'microphone-slash' : 'microphone';
        },
        isLoggedIn() {
            let token = useAuth.getToken();
            return token !== null;
        },
        hasAction() {
            return this.result.action_id &&  this.result.action_id !== null;
        },
        messageState(){
            if(!this.thinking && !this.showSuccess && !this.isRecording){
                return 'default';
            }else if(this.isRecording && !this.thinking){
                return 'recording';
            }else if (this.thinking) {
                return 'thinking';
            }else if(this.showSuccess){
                return 'actionSuccess';
            } else {
                return 'default';
            }
        }
    },
    methods: {

        confirmAction(){
            this.inProgress = true;
            this.thinking = true;

            let formData  = new FormData();
            formData.append('action_id', this.result.action_id);

            this.$http.post('users/voiceCommand', formData).then(() => {
                this.inProgress = false;
                this.thinking = false;
                this.result = {};
                this.showSuccess = true;

                setTimeout(() => {
                    this.showSuccess = false;
                    this.popOverMessage = this.defaultMessage;
                }, 3000);

            }).catch((error) => {
                this.inProgress = false;
                this.thinking = false;
                this.popOverMessage = error.message;
            });
        },

        startRecordingOld() {
            this.isRecording = !this.isRecording;

            if (this.isRecording) {
                this.startRecording();
            } else {
                this.stopRecording();
            }

        },

        handleSuccess(stream) {
            let self = this;
            const options = {mimeType: 'audio/webm'};
            const recordedChunks = [];
            this.mediaRecorder = new MediaRecorder(stream, options);
            this.mediaRecorder.addEventListener('dataavailable', function(e) {
                if (e.data.size > 0) recordedChunks.push(e.data);
            });

            this.progressBar = new ProgressBar.Circle(this.$refs.progressContainer, {
                strokeWidth: 6,
                color: '#5a5aff',
                duration: 20000,
            });

            this.progressBar.animate(1, () => {
                self.stopRecording();
            });

            this.mediaRecorder.addEventListener('stop', function() {
                let blob = new Blob(recordedChunks);
                let formData = new FormData();

                formData.append('aufioFile', blob);
                self.thinking = true;

                axios({
                    method: 'post',
                    url: `${BASE_URL}users/voiceCommand`,
                    headers: {
                        'Api-Key': API_KEY,
                        'Auth-Key': useAuth.getToken(),
                        'Content-Type': 'application/json;charset=utf-8'
                    },
                    data: formData

                }).then((response) => {
                    self.result = response.data.data;
                    self.popOverMessage = self.result.text;

                    if(self.result.url){
                        self.$router.push({path: '/'+self.result.url, props: {extFilters: self.result.filters}});
                    }
                    self.thinking = false;
                    console.log("VoiceSuccess: ", response);

                }).catch((error) => {

                    self.thinking = false;
                    self.popOverMessage = error.message;
                    console.log("ERR: ", error.message);
                });

            });
            this.mediaRecorder.start();
        },


        startRecording() {
            let self = this;
            navigator.mediaDevices.getUserMedia({ audio: true, video: false }).then(self.handleSuccess);
        },
        stopRecording() {
            this.mediaRecorder.stop();
            if (this.progressBar) {
                this.progressBar.stop();
                this.progressBar.destroy();
                this.progressBar = null;
            }
            this.isRecording = false;
        },


    }
}
</script>

<style scoped>

.min-height-100 {
    min-height: 100px;
}

.min-width-300 {
    min-width: calc(276px - 3.4rem);
}

.rec-icon {
    margin: 0 auto;
}


.command-container {
    position: fixed;
    top: 15%;
    right: -20px;
    z-index: 99;
    transition: all 0.5s ease-out;
}

.command-container:hover,
.command-container:focus,
.command-container.active {
    right: 6px;
}

.rec-btn {
    opacity: 0.75;
    transition: all .5s ease;
    background: white;
}

.rec-btn:hover{
    opacity: 1;
}


.radius-100 {
    width: 64px;
    height: 64px;
    border-radius: 50%;
}


.recording-button {
    position: relative;
    display: inline-block;
}

.b-button.recording {
    background-color: red !important;
    border-color: red !important;
}

.progress-container {
    position: absolute;
    top: -6px;
    left: -6px;
    right: -6px;
    bottom: -6px;
    z-index: -1;
}
</style>
