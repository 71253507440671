import useAuth from '@/auth/useAuth'
const currentSoftware = useAuth.getCurrentSoftware()

import {computed} from "@vue/composition-api";
import VueCompositionApi from '@vue/composition-api'
import { i18n } from '@/libs/i18n';
import { useUtils as useI18nUtils } from '@core/libs/i18n';
const { i18n: i18nFunc } = useI18nUtils();
import Vue from "vue";
Vue.use(VueCompositionApi)

function i18nT(str) {
  return i18nFunc(str);
}

export default [
  {
    path: '/organizations',
    name: 'organizations',
    component: () => import('@/views/common/Organizations/Organizations.vue'),
    meta: {
      module: 'company-profile',
      pageTitle: computed(() => i18nT(`Your organizations`)),
      breadcrumb: [
        {
          text: computed(() => i18nT(`Your organizations`)),
          active: true,
        },
      ],
    },
  },
  {
    path: '/:software/company-profile/:tab?',
    name: 'company-profile',
    component: () => import('@/views/common/CompanyProfile/CompanyProfile.vue'),
    meta: {
      module: 'company-profile',
      pageTitle: computed(() => i18nT(`Organization Profile`)),
      breadcrumb: [
        {
          text: computed(() => i18nT(`Organization Profile`)),
          active: true,
        },
      ],
    },
  },
  {
    path: '/:software/company-profile/:orgId',
    name: 'company-profile-id',
    component: () => import('@/views/common/CompanyProfile/CompanyProfile.vue'),
    meta: {
      module: 'company-profile',
      pageTitle: computed(() => i18nT(`Organization Profile`)),
      breadcrumb: [
        {
          text: computed(() => i18nT(`Company Profile`)),
          to: `/${currentSoftware}/company-profile`,
        },
        {
          text: computed(() => i18nT(`Organization`)),
          active: true,
        },
      ],
    },
  },
  {
    path: '/:software/tasks',
    name: 'tasks',
    component: () => import('@/views/common/Tasks/Tasks.vue'),
    meta: {
      module: 'tasks',
      pageTitle: computed(() => i18nT(`Tasks`)),
      breadcrumb: [
        {
          text: computed(() => i18nT(`Tasks`)),
          to: `/${currentSoftware}/tasks`,
        },
        {
          text: computed(() => i18nT(`Task list`)),
          active: true,
        },
      ],
    },
  },
  {
    path: '/:software/task-templates',
    name: 'task-templates',
    component: () => import('@/views/common/Tasks/TaskTemplates.vue'),
    meta: {
      module: 'tasks',
      template: 1,
      pageTitle: computed(() => i18nT(`Task templates`)),
      breadcrumb: [
        {
          text: computed(() => i18nT(`Tasks`)),
          to: `/${currentSoftware}/tasks`,
        },
        {
          text: computed(() => i18nT(`Task templates`)),
          active: true,
        },
      ],
    },
  },
  {
    path: '/:software/task/view/:id',
    name: 'task-view',
    component: () => import('@/views/common/Tasks/TaskDetail.vue'),
    meta: {
      module: 'tasks',
      navActiveLink: 'tasks',
      pageTitle: computed(() => i18nT(`View Task`)),
      breadcrumb: [
        {
          text: computed(() => i18nT(`Tasks`)),
          to: `/${currentSoftware}/tasks`,
        },
        {
          text: computed(() => i18nT(`Task list`)),
          to: `/${currentSoftware}/tasks`,
        },
        {
          text: computed(() => i18nT(`Task`)),
          active: true,
        },
      ],
    },
  },
  {
    path: '/:software/task-template/view/:id',
    name: 'task-template-view',
    component: () => import('@/views/common/Tasks/TaskDetail.vue'),
    meta: {
      module: 'tasks',
      navActiveLink: 'task-templates',
      pageTitle: computed(() => i18nT(`View task template`)),
      breadcrumb: [
        {
          text: computed(() => i18nT(`Tasks`)),
          to: `/${currentSoftware}/tasks`,
        },
        {
          text: computed(() => i18nT(`Task templates`)),
          to: `/${currentSoftware}/task-templates`,
        },
        {
          text: computed(() => i18nT(`Task templates`)),
          active: true,
        },
      ],
    },
  },
  {
    path: '/:software/edit-task-template/:id',
    name: 'edit-task-template',
    component: () => import('@/views/common/Tasks/EditTask.vue'),
    meta: {
      module: 'tasks',
      mode: 'write',
      navActiveLink: 'edit-task-templates',
      pageTitle: computed(() => i18nT(`Edit task template`)),
      breadcrumb: [
        {
          text: computed(() => i18nT(`Tasks`)),
          to: `/${currentSoftware}/tasks`,
        },
        {
          text: computed(() => i18nT(`Task templates`)),
          to: `/${currentSoftware}/task-templates`,
        },
        {
          text: computed(() => i18nT(`Task templates`)),
          active: true,
        },
      ],
    },
  },
  {
    path: '/:software/edit-tasks/:id',
    name: 'edit-tasks',
    component: () => import('@/views/common/Tasks/EditTask.vue'),
    meta: {
      module: 'tasks',
      mode: 'write',
      navActiveLink: 'tasks',
      pageTitle: computed(() => i18nT(`Edit Task`)),
      breadcrumb: [
        {
          text: computed(() => i18nT(`Tasks`)),
          to: `/${currentSoftware}/tasks`,
        },
        {
          text: computed(() => i18nT(`Task list`)),
          to: `/${currentSoftware}/tasks`,
        },
        {
          text: computed(() => i18nT(`Task`)),
          active: true,
        },
      ],
    },
  },
  {
    path: '/:software/create-task/:templateId?',
    name: 'create-task',
    component: () => import('@/views/common/Tasks/EditTask.vue'),
    meta: {
      module: 'tasks',
      mode: 'write',
      navActiveLink: 'tasks',
      pageTitle: computed(() => i18nT(`Create task`)),
      breadcrumb: [
        {
          text: computed(() => i18nT(`Tasks`)),
          to: `/${currentSoftware}/tasks`,
        },
        {
          text: computed(() => i18nT(`Task list`)),
          to: `/${currentSoftware}/tasks`,
        },
        {
          text: computed(() => i18nT(`Task`)),
          active: true,
        },
      ],
    },
  },
  {
    path: '/:software/create-task-template/',
    name: 'create-task-template',
    component: () => import('@/views/common/Tasks/EditTask.vue'),
    meta: {
      module: 'tasks',
      mode: 'write',
      navActiveLink: 'task-templates',
      template: 1,
      pageTitle: computed(() => i18nT(`Create Task Template`)),
      breadcrumb: [
        {
          text: computed(() => i18nT(`Tasks`)),
          to: `/${currentSoftware}/tasks`,
        },
        {
          text: computed(() => i18nT(`Task templates`)),
          to: `/${currentSoftware}/task-templates`,
        },
        {
          text: computed(() => i18nT(`Task template`)),
          active: true,
        },
      ],
    },
  },
  {
    path: '/:software/documents',
    name: 'documents',
    component: () => import('@/views/common/Documents/Documents.vue'),
    meta: {
      module: 'documents',
      pageTitle: computed(() => i18nT(`Documents`)),
      breadcrumb: [
        {
          text: computed(() => i18nT(`Documents`)),
          to: `/${currentSoftware}/documents`,
        },
        {
          text: computed(() => i18nT(`Document List`)),
          active: true,
        },
      ],
    },
  },
  {
    path: '/:software/document-templates',
    name: 'document-templates',
    component: () => import('@/views/common/Documents/DocumentTemplates.vue'),
    meta: {
      module: 'documents',
      template: 1,
      pageTitle: computed(() => i18nT(`Document templates`)),
      breadcrumb: [
        {
          text: computed(() => i18nT(`Documents`)),
          to: `/${currentSoftware}/documents`,
        },
        {
          text: computed(() => i18nT(`Document templates`)),
          active: true,
        },
      ],
    },
  },
  {
    path: '/:software/create-document/',
    name: 'create-document',
    component: () => import('@/views/common/Documents/EditDocument.vue'),
    meta: {
      module: 'documents',
      mode: 'write',
      navActiveLink: 'documents',
      pageTitle: computed(() => i18nT(`Create Document`)),
      breadcrumb: [
        {
          text: computed(() => i18nT(`Documents`)),
          to: `/${currentSoftware}/documents`,
        },
        {
          text: computed(() => i18nT(`Document List`)),
          to: `/${currentSoftware}/documents`,
        },
        {
          text: computed(() => i18nT(`Document`)),
          active: true,
        },
      ],
    },
  },
  {
    path: '/:software/create-document-template/',
    name: 'create-document-template',
    component: () => import('@/views/common/Documents/EditDocument.vue'),
    meta: {
      module: 'documents',
      mode: 'write',
      navActiveLink: 'document-templates',
      template: 1,
      pageTitle: computed(() => i18nT(`Create document template`)),
      breadcrumb: [
        {
          text: computed(() => i18nT(`Documents`)),
          to: `/${currentSoftware}/documents`,
        },
        {
          text: computed(() => i18nT(`Document templates`)),
          to: `/${currentSoftware}/document-templates`,
        },
        {
          text: computed(() => i18nT(`Document template`)),
          active: true,
        },
      ],
    },
  },
  {
    path: '/:software/edit-document/:id',
    name: 'edit-document',
    component: () => import('@/views/common/Documents/EditDocument.vue'),
    meta: {
      module: 'documents',
      mode: 'write',
      navActiveLink: 'documents',
      pageTitle: computed(() => i18nT(`Edit document`)),
      breadcrumb: [
        {
          text: computed(() => i18nT(`Documents`)),
          to: `/${currentSoftware}/documents`,
        },
        {
          text: computed(() => i18nT(`Document List`)),
          to: `/${currentSoftware}/documents`,
        },
        {
          text: computed(() => i18nT(`Document`)),
          active: true,
        },
      ],
    },
  },
  {
    path: '/:software/edit-document-template/:id',
    name: 'edit-document-template',
    component: () => import('@/views/common/Documents/EditDocument.vue'),
    meta: {
      module: 'documents',
      mode: 'write',
      navActiveLink: 'document-templates',
      pageTitle: computed(() => i18nT(`Edit document template`)),
      breadcrumb: [
        {
          text: computed(() => i18nT(`Documents`)),
          to: `/${currentSoftware}/documents`,
        },
        {
          text: computed(() => i18nT(`Document templates`)),
          to: `/${currentSoftware}/document-templates`,
        },
        {
          text: computed(() => i18nT(`Document template`)),
          active: true,
        },
      ],
    },
  },
  {
    path: '/:software/task-groups/',
    name: 'task-groups',
    component: () => import('@/views/common/TaskGroups/TaskGroups.vue'),
    meta: {
      module: 'tasks',
      pageTitle: computed(() => i18nT(`Task groups`)),
      breadcrumb: [
        {
          text: computed(() => i18nT(`Tasks`)),
          to: `/${currentSoftware}/tasks`,
        },
        {
          text: computed(() => i18nT(`Task groups`)),
          active: true,
        },
      ],
    },
  },
  {
    path: '/:software/document-groups/',
    name: 'document-groups',
    component: () => import('@/views/common/DocumentGroups/DocumentGroups.vue'),
    meta: {
      module: 'documents',
      pageTitle: computed(() => i18nT(`Document groups`)),
      breadcrumb: [
        {
          text: computed(() => i18nT(`Documents`)),
          to: `/${currentSoftware}/documents`,
        },
        {
          text: computed(() => i18nT(`Document groups`)),
          active: true,
        },
      ],
    },
  },
  {
    path: '/:software/create-task-group/',
    name: 'create-task-group',
    component: () => import('@/views/common/TaskGroups/EditTaskGroup.vue'),
    meta: {
      module: 'tasks',
      mode: 'write',
      navActiveLink: 'task-groups',
      pageTitle: computed(() => i18nT(`Create task group`)),
      breadcrumb: [
        {
          text: computed(() => i18nT(`Tasks`)),
          to: `/${currentSoftware}/tasks`,
        },
        {
          text: computed(() => i18nT(`Task groups`)),
          to: `/${currentSoftware}/task-groups`,
        },
        {
          text: computed(() => i18nT(`Task group`)),
          active: true,
        },
      ],
    },
  },
  {
    path: '/:software/create-document-group/',
    name: 'create-document-group',
    component: () =>
      import('@/views/common/DocumentGroups/EditDocumentGroup.vue'),
    meta: {
        module: 'documents',
        mode: 'write',
      navActiveLink: 'document-groups',
      pageTitle: computed(() => i18nT(`Create Document group`)),
      breadcrumb: [
        {
          text: computed(() => i18nT(`Documents`)),
          to: `/${currentSoftware}/documents`,
        },
        {
          text: computed(() => i18nT(`Document groups`)),
          to: `/${currentSoftware}/document-groups`,
        },
        {
          text: computed(() => i18nT(`Document group`)),
          active: true,
        },
      ],
    },
  },
  {
    path: '/:software/edit-task-group/:id',
    name: 'edit-task-group',
    component: () => import('@/views/common/TaskGroups/EditTaskGroup.vue'),
    meta: {
        module: 'tasks',
        mode: 'write',
      navActiveLink: 'task-groups',
      pageTitle: computed(() => i18nT(`Edit task group`)),
      breadcrumb: [
        {
          text: computed(() => i18nT(`Tasks`)),
          to: `/${currentSoftware}/tasks`,
        },
        {
          text: computed(() => i18nT(`Task groups`)),
          to: `/${currentSoftware}/task-groups`,
        },
        {
          text: computed(() => i18nT(`Task group`)),
          active: true,
        },
      ],
    },
  },
  {
    path: '/:software/edit-document-group/:id',
    name: 'edit-document-group',
    component: () =>
      import('@/views/common/DocumentGroups/EditDocumentGroup.vue'),
    meta: {
        module: 'documents',
        mode: 'write',
      pageTitle: computed(() => i18nT(`Edit Document group`)),
      breadcrumb: [
        {
          text: computed(() => i18nT(`Documents`)),
          to: `/${currentSoftware}/documents`,
        },
        {
          text: computed(() => i18nT(`Document groups`)),
          to: `/${currentSoftware}/document-groups`,
        },
        {
          text: computed(() => i18nT(`Document group`)),
          active: true,
        },
      ],
    },
  },
  {
    path: '/messages/inbox',
    name: 'inbox-all',
    component: () => import('@/views/apps/email/Email.vue'),
    meta: {
      contentRenderer: 'sidebar-left',
      contentClass: 'email-application',
      breadcrumb: [
        {
          text: computed(() => i18nT(`Messages`)),
          to: `/messages/inbox`,
          active: true,
        },
      ],
    },
  },
  {
    path: '/messages/archive',
    name: 'archive-all',
    component: () => import('@/views/apps/email/Email.vue'),
    meta: {
      contentRenderer: 'sidebar-left',
      contentClass: 'email-application',
      breadcrumb: [
        {
          text: computed(() => i18nT(`Messages`)),
          to: `/messages/inbox`,
          active: true,
        },
      ],
    },
  },
  {
    path: '/messages/inbox/recipient/:id',
    name: 'inbox-send',
    component: () => import('@/views/apps/email/Email.vue'),
    meta: {
      contentRenderer: 'sidebar-left',
      contentClass: 'email-application',
    },
  },
  {
    path: '/messsages/inbox/message/:msgid',
    name: 'inbox-message',
    component: () => import('@/views/apps/email/Email.vue'),
    meta: {
      contentRenderer: 'sidebar-left',
      contentClass: 'email-application',
      breadcrumb: [
        {
          text: computed(() => i18nT(`Messages`)),
          to: `/${currentSoftware}/inbox`,
        },
      ],
    },
  },
  {
    path: '/:software/calendar',
    name: 'calendar',
    component: () => import('@/views/common/Calendar/Calendar.vue'),
    meta: {
      module: 'calendar',
      pageTitle: computed(() => i18nT(`Calendar`)),
      breadcrumb: [
        {
          text: computed(() => i18nT(`Calendar`)),
          active: true,
        },
      ],
    },
  },
  {
    path: '/messages/inbox/new',
    name: 'inbox-new',
    component: () => import('@/views/apps/email/Email.vue'),
    meta: {
      contentRenderer: 'sidebar-left',
      contentClass: 'email-application',
      breadcrumb: [
        {
          text: computed(() => i18nT(`Messages`)),
          to: `/${currentSoftware}/inbox`,
        },
      ],
    },
  },
  {
    path: '/messages/inbox/:folder',
    name: 'inbox-folder',
    component: () => import('@/views/apps/email/Email.vue'),
    beforeEnter(to, _, next) {
      if (
        ['new', 'sent', 'draft', 'starred', 'spam', 'trash'].includes(
          to.params.folder
        )
      )
        next()
      else next({ name: 'error-404' })
    },
    meta: {
      contentRenderer: 'sidebar-left',
      contentClass: 'email-application',
      navActiveLink: 'apps-email',
    },
  },
  {
    path: '/apply/:id',
    name: 'apply',
    component: () => import('@/views/common/Apply/Apply.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      action: 'read',
    },
  },
  {
    path: '/invoice/:id/h/:hash',
    name: 'invoice-public',
    component: () =>
        import('@/views/accounting/Invoices/InvoicePublic.vue'),
    meta: {
      layout: 'full',
      module: 'bank',
      pageTitle: computed(() => i18nT(`Your Invoice`)),
    },
  },
  {
    path: '/quote/:id/h/:hash',
    name: 'quote-public',
    component: () =>
        import('@/views/accounting/Quotes/QuotePublic.vue'),
    meta: {
      layout: 'full',
      module: 'bank',
      pageTitle: computed(() => i18nT(`Your Invoice`)),
    },
  },
  {
    path: '/resume/:id',
    name: 'resume',
    component: () => import('@/views/common/Resume/Resume.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      action: 'read',
      pageTitle: computed(() => i18nT(`Resume`)),
      breadcrumb: [
        {
          text: computed(() => i18nT(`Resume`)),
          active: true,
        },
      ],
    },
  },
  {
    path: '/company/:id',
    name: 'company',
    component: () =>
      import('@/views/common/CompanyProfile/CompanyProfilePublic.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      action: 'read',
      pageTitle: computed(() => i18nT(`Company`)),
      breadcrumb: [
        {
          text: computed(() => i18nT(`Company`)),
          active: true,
        },
      ],
    },
  },
  // {
  //   path: '/:software/settings/',
  //   name: 'settings-general',
  //
  //   redirect: '/error-404',
  // },
  {
    path: '/:software/settings/:tab',
    name: 'settings',
    component: () => import('@/views/common/Settings/Settings.vue'),
    beforeEnter(to, _, next) {
      let soft = to.params.software;
      let selectedTab = to.params.tab || 'configuration';
      if(soft === 'ca' || !soft){
        next({name: 'error-404'});
      } else {
        next({ params: { software: soft, tab: selectedTab } })
      }
    },
    meta: {
      //TODO: add settings module permissions
      pageTitle: computed(() => i18nT(`Settings`)),
      breadcrumb: [
        {
          text: computed(() => i18nT(`Settings`)),
          to: `/${currentSoftware}/settings`,
        },
      ],
    },
  },
  {
    path: '/settings/connect/:provider',
    name: 'connect-stripe',
    component: () => import('@/views/common/Settings/Connect/ConnectStripe.vue'),
    meta: {
      //TODO: add settings module permissions
      pageTitle: computed(() => i18nT(`Payment provider integration`)),
      breadcrumb: [
        {
          text: computed(() => i18nT(`Settings`)),
          to: `/${currentSoftware}/settings`,
        },
        {
          text: computed(() => i18nT(`Payment provider integration`)),
          active: true,
        },
      ],
    },
  },
  {
    path: '/:software/trash',
    name: 'trash',
    component: () => import('@/views/common/Trash/Trash.vue'),
    meta: {
      module: 'trash',
      pageTitle: computed(() => i18nT(`Trash`)),
      breadcrumb: [
        {
          text: computed(() => i18nT(`Trash`)),
          to: `/${currentSoftware}/trash`,
        }
      ],
    },
  }
]
