/* eslint-disable */
import {computed} from "@vue/composition-api";
import VueCompositionApi from '@vue/composition-api'
import { i18n } from '@/libs/i18n';
import { useUtils as useI18nUtils } from '@core/libs/i18n';
const { i18n: i18nFunc } = useI18nUtils();
import Vue from "vue";
Vue.use(VueCompositionApi)

function i18nT(str) {
  return i18nFunc(str);
}

export default [
  {
    path: '/hr/leave_absence',
    name: 'hr-leave-absence',
    component: () => import('@/views/hr/LeaveRequest/LeaveRequest.vue'),
    meta: {
      roles: ['Staff', 'Client'],
      module: 'leave_absence',
      pageTitle: computed(() => i18nT(`Time off`)),
      breadcrumb: [
        {
          text: computed(() => i18nT(`Time off`)),
          active: true
        }
      ]
    }
  },
  {
    path: '/hr/timesheets',
    name: 'hr-timesheets',
    component: () => import('@/views/hr/Timesheet/TimesheetList.vue'),
    meta: {
      module: 'timesheets',
      roles: ['Staff', 'Client'],
      pageTitle: computed(() => i18nT(`Timesheets`)),
      breadcrumb: [
        {
          text: computed(() => i18nT(`Timesheets`)),
          active: true
        }
      ]
    }
  }
]

/* eslint-enable */
