/* eslint-disable */
import {computed} from "@vue/composition-api";
import VueCompositionApi from '@vue/composition-api'
import { i18n } from '@/libs/i18n';
import { useUtils as useI18nUtils } from '@core/libs/i18n';
const { i18n: i18nFunc } = useI18nUtils();
import Vue from "vue";
Vue.use(VueCompositionApi)

function i18nT(str) {
  return i18nFunc(str);
}

export default [
  {
    path: '/re/job-offers',
    name: 'job-offers',
    component: () => import('@/views/recruiting/JobOffers/JobOfferList.vue'),
    meta: {
      module: 'job-offers',
      pageTitle: computed(() => i18nT(`Job offer list`)),
      breadcrumb: [
        {
          text: computed(() => i18nT(`Job offers`)),
          active: true,
        },
      ],
    },
  },
  {
    path: '/re/job-offers/edit/:id',
    name: 'create-edit-job-offer',
    component: () => import('@/views/recruiting/JobOffers/EditJobOffer.vue'),
    meta: {
      module: 'job-offers',
      mode: 'write',
      navActiveLink: 'job-offers',
      pageTitle: computed(() => i18nT(`Edit job offer`)),
      breadcrumb: [
        {
          text: computed(() => i18nT(`Job offers`)),
          to: '/re/job-offers',
        },
        {
          text: computed(() => i18nT(`Edit job offer`)),
          active: true,
        },
      ],
    },
  },
  {
    path: '/re/job-offers/view/:id',
    name: 'view-job-offer',
    component: () => import('@/views/recruiting/JobOffers/ViewJobOffer.vue'),
    meta: {
      module: 'job-offers',
      navActiveLink: 'job-offers',
      pageTitle: computed(() => i18nT(`Job offer`)),
      breadcrumb: [
        {
          text: computed(() => i18nT(`Job offers`)),
          to: '/re/job-offers',
        },
        {
          text: computed(() => i18nT(`Job offer details`)),
          active: true,
        },
      ],
    },
  },
  {
    path: '/re/cvs',
    name: 'cvs',
    component: () => import('@/views/recruiting/Cv/CvList.vue'),
    meta: {
      module: 'cv',
      pageTitle: computed(() => i18nT(`CV Database`)),
      breadcrumb: [
        {
          text: computed(() => i18nT(`CV Database`)),
          active: true,
        },
      ],
    },
  },
  {
    path: '/re/cvs/preview/:id',
    name: 'cv-preview',

    component: () => import('@/views//recruiting/Cv/SingleCV.vue'),
    meta: {
      module: 'cv',
      navActiveLink: 'cvs',
      pageTitle: computed(() => i18nT(`CV Database`)),
      breadcrumb: [
        {
          text: computed(() => i18nT(`CV Database`)),
          to: '/re/cvs',
        },
        {
          text: computed(() => i18nT(`CV Preview`)),
          active: true,
        },
      ],
    },
  },
  {
    path: '/re/cvs/edit/:id',
    name: 'cv-edit',
    component: () => import('@/views/recruiting/Candidates/EditCandidate.vue'),
    meta: {
      module: 'cv',
      navActiveLink: 'cvs',
      pageTitle: computed(() => i18nT(`Edit CV`)),
      breadcrumb: [
        {
          text: computed(() => i18nT(`CV Database`)),
          to: '/re/cvs',
        },
        {
          text: computed(() => i18nT(`CV Edit`)),
          active: true,
        },
      ],
    },
  },
  {
    path: '/re/cvs/create/',
    name: 'create-cv',
    component: () => import('@/views/recruiting/Candidates/EditCandidate.vue'),
    meta: {
      module: 'cv',
      mode: 'write',
      navActiveLink: 'cvs',
      pageTitle: computed(() => i18nT(`Create CV`)),
      breadcrumb: [
        {
          text: computed(() => i18nT(`CV Database`)),
          to: '/re/cvs',
        },
        {
          text: computed(() => i18nT(`Create CV`)),
          active: true,
        },
      ],
    },
  },
  {
    path: '/re/applications/:stage',
    name: 'applications',
    component: () => import('@/views/recruiting/Candidates/Candidates.vue'),
    meta: {
      module: 'applications',
      navActiveLink: 'applications',
      pageTitle: computed(() => i18nT(`Application`)),
      breadcrumb: [
        {
          text: computed(() => i18nT(`Applications`)),
          active: true,
        },
      ],
    },
  },
  {
    path: '/re/applications',
    redirect: '/re/applications/ALL',
  },
  {
    path: '/re/create-application/',
    name: 'create-applications',
    component: () => import('@/views/recruiting/Candidates/EditCandidate.vue'),
    meta: {
      module: 'applications',
      mode: 'write',
      navActiveLink: 'applications',
      pageTitle: computed(() => i18nT(`Application`)),
      breadcrumb: [
        {
          text: computed(() => i18nT(`Applications`)),
          to: '/re/applications',
        },
        {
          text: computed(() => i18nT(`Application`)),
          active: true,
        },
      ],
    },
  },
  {
    path: '/re/applications/edit/:id',
    name: 'edit-application',
    component: () => import('@/views/recruiting/Candidates/EditCandidate.vue'),
    meta: {
      module: 'applications',
      mode: 'write',
      navActiveLink: 'applications',
      pageTitle: computed(() => i18nT(`Application`)),
      breadcrumb: [
        {
          text: computed(() => i18nT(`Applications`)),
          to: '/re/applications',
        },
        {
          text: computed(() => i18nT(`Application`)),
          active: true,
        },
      ],
    },
  },
  {
    path: '/re/applications/view/:id',
    name: 'view-application',
    component: () =>
      import('@/views/recruiting/Candidates/CandidateDetail.vue'),
    meta: {
      module: 'applications',
      navActiveLink: 'applications',
      pageTitle: computed(() => i18nT(`Application`)),
      breadcrumb: [
        {
          text: computed(() => i18nT(`Applications`)),
          to: '/re/applications',
        },
        {
          text: computed(() => i18nT(`Application`)),
          active: true,
        },
      ],
    },
  },
]

/* eslint-enable */
