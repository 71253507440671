/* eslint-disable */
import {computed} from "@vue/composition-api";
import VueCompositionApi from '@vue/composition-api'
import { i18n } from '@/libs/i18n';
import { useUtils as useI18nUtils } from '@core/libs/i18n';
const { i18n: i18nFunc } = useI18nUtils();
import Vue from "vue";
import axiosIns from "@/libs/axios";
Vue.use(VueCompositionApi)

function i18nT(str) {
  return i18nFunc(str);
}

export default [
  {
    path: '/ca/dashboard',
    name: 'ca-dashboard',
    component: () => import('@/views/dashboard/ca_Dashboard.vue'),
    meta: {
      pageTitle: computed(() => i18nT(`Dashboard`)),
      breadcrumb: [
        {
          text: computed(() => i18nT(`Dashboard`)),
          active: true,
        },
      ],
    },
  },
  {
    path: '/ca/applications/:stage',
    name: 'ca-applications',
    component: () => import('@/views/candidate/Applications/Applications.vue'),
    meta: {
      navActiveLink: 'ca-applications-redirect',
      pageTitle: computed(() => i18nT(`Applications`)),
      breadcrumb: [
        {
          text: computed(() => i18nT(`Applications`)),
          active: true,
        },
      ],
    },
  },
  {
    path: '/ca/applications',
    name: 'ca-applications-redirect',
    beforeEnter: (to, from, next) => {
      axiosIns.get('candidates/stages').then((res) => {
        let allStages = res.data.data.stages;
        let nextStage = allStages.find((stage) => stage.count > 0);
        if (nextStage) {
          next(`/ca/applications/${nextStage.short}`);
        } else {
            next(`/ca/applications/APPLIED`);
        }
      }).catch(() => {
        next(`/ca/applications/APPLIED`);
      });
    },
  },
  {
    path: '/ca/resume',
    name: 'ca-resume',
    component: () => import('@/views/candidate/Resume/Resume.vue'),
    meta: {
      roles: ['Freelancer'],
      pageTitle: computed(() => i18nT(`Resume`)),
      breadcrumb: [
        {
          text: computed(() => i18nT(`Resume`)),
          active: true,
        },
      ],
    },
  },
  {
    path: '/ca/resume/settings',
    name: 'ca-settings',
    component: () => import('@/views/candidate/Settings/Settings.vue'),
    meta: {
      pageTitle: computed(() => i18nT(`Settings`)),
      breadcrumb: [
        {
          text: computed(() => i18nT(`Settings`)),
          active: true,
        },
      ],
    },
  },
  {
    path: '/ca/application/:id',
    name: 'ca-application',
    component: () => import('@/views/candidate/Applications/Offer.vue'),
    meta: {
      navActiveLink: 'ca-applications-redirect',
      pageTitle: computed(() => i18nT(`Job application`)),
      breadcrumb: [
        {
          text: computed(() => i18nT(`Applications`)),
          to: '/ca/applications',
        },
        {
          text: computed(() => i18nT(`Job application`)),
          active: true,
        },
      ],
    },
  },
]

/* eslint-enable */
