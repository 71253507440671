ƒ<template>
  <div
    id="app"
    class="h-100"
    :class="[skinClasses]"
  >
    <component v-if="langLoaded" :is="layout">
      <router-view :key="$route.fullPath" />
    </component>

    <scroll-to-top v-if="enableScrollToTop" />
    <voice-commands v-if="available"></voice-commands>

  </div>
</template>

<script>
import ScrollToTop from '@core/components/scroll-to-top/ScrollToTop.vue'
import VoiceCommands from "@/views/common/VoiceCommands/VoiceCommands.vue";
// This will be populated in `beforeCreate` hook
import { $themeColors, $themeBreakpoints, $themeConfig } from '@themeConfig'
import { provideToast } from 'vue-toastification/composition'
import { watch } from '@vue/composition-api'
import useAppConfig from '@core/app-config/useAppConfig'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import { useWindowSize, useCssVar } from '@vueuse/core'
import useAuth from '@/auth/useAuth'
import store from '@/store'
import { BASE_URL, API_KEY } from '@core/utils/constants'
import router from '@/router'
import axios from 'axios';
import { i18n } from '@/libs/i18n';
import { useUtils as useI18nUtils } from '@core/libs/i18n';
const { i18nK } = useI18nUtils();
function i18nT(str){
    return i18nK(str)
}

import _ from 'lodash'

const LayoutVertical = () => import('@/layouts/vertical/LayoutVertical.vue')

const LayoutFull = () => import('@/layouts/full/LayoutFull.vue')

export default {
  components: {
    // Layouts
    LayoutVertical,
    LayoutFull,
      VoiceCommands,
    ScrollToTop
  },
  // ! We can move this computed: layout & contentLayoutType once we get to use Vue 3
  // Currently, router.currentRoute is not reactive and doesn't trigger any change
  computed: {
    layout() {
      if (this.$route.meta.layout === 'full') return 'layout-full'
      return `layout-${this.contentLayoutType}`
    },
    contentLayoutType() {
      return this.$store.state.appConfig.layout.type
    },
    available(){
      return process.env.VUE_APP_DEV_MODE == "true";
    }
  },
  data() {
    return {
      langLoaded: false
    }
  },
  beforeCreate() {
    // Set colors in theme
    const colors = [
      'primary',
      'secondary',
      'success',
      'info',
      'warning',
      'danger',
      'light',
      'dark'
    ]

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = colors.length; i < len; i++) {
      $themeColors[colors[i]] = useCssVar(
        `--${colors[i]}`,
        document.documentElement
      ).value.trim()
    }

    // Set Theme Breakpoints
    const breakpoints = ['xs', 'sm', 'md', 'lg', 'xl']

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = breakpoints.length; i < len; i++) {
      $themeBreakpoints[breakpoints[i]] = Number(
        useCssVar(
          `--breakpoint-${breakpoints[i]}`,
          document.documentElement
        ).value.slice(0, -2)
      )
    }

    // Set RTL
    const { isRTL } = $themeConfig.layout
    document.documentElement.setAttribute('dir', isRTL ? 'rtl' : 'ltr')
  },
  watch: {
    $route(to) {
      try {
        const curSoftware = useAuth.getCurrentSoftware()
        const curWorkspace = useAuth.getCurWorkspace()
        const urlSoftware = to.path.split('/')[1]
        const pageName = to.path.split('/')[2]

        if (curSoftware && curWorkspace.Id) {
          const softwares = ['en', 'hr', 'on', 're', 'ac']
          if (softwares.includes(urlSoftware)) {
            if (urlSoftware != curSoftware) {
              const curSoftware = _.values(useAuth.getSoftwares()).find(
                software => software.short === urlSoftware
              )
              this.$http
                .post(BASE_URL + 'main/switchSoftware', {
                  software_id: curSoftware.id
                })
                .then(() => {
                  useAuth.setCurrentSoftware(urlSoftware)
                })
            }
          }
        }
        if (pageName && pageName !== 'company-profile') {

          if (curWorkspace !== null && curWorkspace.Id && curWorkspace.WizzardCompleted !== 1) {
            router.push({
              name: 'company-profile',
              params: { software: curSoftware }
            })
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: i18nT(`You must complete the company profile`),
                icon: 'AlertCircleIcon',
                variant: 'danger'
              }
            })
          }
        }
      } catch (ex) {
        console.log(ex)
      }
    }
  },
  setup() {
    const { skin, skinClasses } = useAppConfig()
    const { enableScrollToTop } = $themeConfig.layout

    // If skin is dark when initialized => Add class to body
    if (skin.value === 'dark') document.body.classList.add('dark-layout')

    // Provide toast for Composition API usage
    // This for those apps/components which uses composition API
    // Demos will still use Options API for ease
    provideToast({
      hideProgressBar: true,
      closeOnClick: false,
      closeButton: false,
      icon: false,
      timeout: 3000,
      transition: 'Vue-Toastification__fade'
    })

    // Set Window Width in store
    store.commit('app/UPDATE_WINDOW_WIDTH', window.innerWidth)
    const { width: windowWidth } = useWindowSize()
    watch(windowWidth, val => {
      store.commit('app/UPDATE_WINDOW_WIDTH', val)
    })

    return {
      skinClasses,
      enableScrollToTop
    }
  },
  mounted() {
    this.loadLangs()
  },
  methods: {
    loadLangs(whichLocale = 'en') {
      axios.get(`${BASE_URL}system/locales?lang=`+whichLocale, {
        headers: {
          'Api-Key': API_KEY,
        },
      }).then(({data}) => {

        const locales = Object.keys(data);

        locales.forEach((locale) => {
          i18n.setLocaleMessage(locale, data[locale]);
        });

        const locale = localStorage.getItem('locale', 'en');
        i18n.locale = locale
        this.langLoaded = true

      }).catch(ex => {
        console.log(ex)
      });
    }
  }
}
</script>
